function workListInit() {
    gsap.to(".work-list li", {
        duration: 1,
        yPercent: 0,
        opacity: 1,
        ease: "power1.inOut",
        stagger: {
            amount: 1.5,
            // from: "center",
        }
    });
}
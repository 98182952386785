function footerMtopInit() {
    const footerHeight = footer.offsetHeight;
    const mainContainer = document.querySelector('.main-container');

    main.style.marginBottom = `${footerHeight}px`;

    gsap.timeline({
        scrollTrigger: {
            trigger: mainContainer,
            markers: false,
            start: 'bottom bottom',
            end: 'bottom bottom',

            onLeave() {
                footer.classList.add('visible');
            },
            onEnterBack() {
                footer.classList.remove('visible');
            },
        }
    })
}

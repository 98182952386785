function fixedParallaxVideoInit() {
    const fixedParallaxVideo = document.querySelector('.fixed-parallax-video');

    if (fixedParallaxVideo) {
        gsap.timeline({
            scrollTrigger: {
                trigger: fixedParallaxVideo,
                markers: false,
                start: 'top bottom',
                end: `bottom`,
                // end: `+=${fixedParallaxVideo.offsetHeight}px`,

                onEnter() {
                    fixedParallaxVideo.classList.add('video-start');
                },

                onLeave() {
                    fixedParallaxVideo.classList.remove('video-start');
                },

                onEnterBack() {
                    fixedParallaxVideo.classList.add('video-start');
                },

                onLeaveBack() {
                    fixedParallaxVideo.classList.remove('video-start');
                },

                /* onToggle() {
                    fixedParallaxVideo.classList.toggle('scroll-start');
                } */
            }
        })
    }
}
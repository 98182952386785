const body = document.querySelector('body');
const header = document.querySelector('header');
const menu = document.querySelector(".megamenu");
const menuBtn = document.querySelector('.header-button');
const main = document.querySelector('main');
const footer = document.querySelector('footer');



const btnFixed = document.querySelector('.btn-fixed-mobile');
function toggleFixBtn() {
    gsap.to(".btn-fixed-mobile", {
        scrollTrigger: {
            trigger: '.section--mail',
            markers: false,
            start: 'top center+=10%',
            onEnter: () => btnFixed.classList.remove('active'),
            onEnterBack: () => btnFixed.classList.add('active'),
            onLeaveBack: () => btnFixed.classList.add('active')
        }
    })
}

const myModalEl = document.getElementById('modalMail')
myModalEl.addEventListener('show.bs.modal', event => {
    btnFixed.classList.remove('active')
})

myModalEl.addEventListener('hide.bs.modal', event => {
    btnFixed.classList.add('active')
})

window.addEventListener("DOMContentLoaded", (event) => {
    const url = 'http://localhost:3000';

    if (window.location.href === url + '/?m=thx') {
        var myModal = new bootstrap.Modal(document.getElementById('modalThankYou'))
        myModal.show();
    }


    toggleFixBtn()
    parallaxImage();
    fixedParallaxVideoInit();

    scrollFixed();
    scrollBlurInit();
    wordMaskInit();
    footerMtopInit();
    AOS.init();



    workListInit();


    // accordionEmptyStateInit(); // ! da sistemare



});

window.addEventListener("resize", (event) => {
    footerMtopInit();
});

window.addEventListener("scroll", (event) => {
    // toggleFixBtn()
});


